/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-07-05",
    versionChannel: "nightly",
    buildDate: "2023-07-05T00:20:17.690Z",
    commitHash: "e716b3fd7fff3ac844b9e5cfb25b67b81c44af64",
};
